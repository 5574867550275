import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Config } from '../common/config';
import { MyStorageService } from './my-storage.service';
import { User } from '../models/user';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
  isUpdating: boolean;
  authData: User = new User();
  private authSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public auth$: Observable<User>;
  constructor(
    private http: HttpClient,
    private config: Config,
    private storage: MyStorageService,
    private tokenService: TokenService,
    private router: Router
  ) {
    this.isUpdating = false;
    this.auth$ = this.authSubject$.asObservable();
  }

  login(loginData: any): Observable<any> {
    const url: string = this.config.authUrl;
    const client: string = this.config.clientId;
    const data: any = `grant_type=password&password=${
      loginData.password
    }&username=${loginData.userName}&client_id=${client}`;
    return this.http.post(url, data, { headers: this.addHeaderLogin() }).pipe(
      map(resp => {
        if (resp) {
          this.tokenService.setToken(resp);
        }
      })
    );
  }

  refreshToken(): Observable<any> {
    const url: string = this.config.authUrl;
    const clientId: string = this.config.clientId;
    const data: any = `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}&client_id=${clientId}`;
    return this.http.post(url, data, { headers: this.addHeaderLogin() }).pipe(
      map(resp => {
        if (resp) {
          this.tokenService.setToken(resp);
        }
        return resp;
      })
    );
  }

  logout() {
    console.log('logout: ');
    this.storage.delete('authorizationData');
    this.router.navigate(['/login']);
  }
  getAuthToken(): string {
    const currentToken = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentToken !== null) {
      return currentToken.access_token;
    }
    return '';
  }

  getRefreshToken(): string {
    const currentToken = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentToken !== null) {
      return currentToken.refresh_token;
    }
    return '';
  }

  private addHeaderLogin(): HttpHeaders {
    const currentUser = JSON.parse(localStorage.getItem('authorizationData'));
    //     if (currentUser && currentUser.access_token) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return headers;
    //     }
  }
}
