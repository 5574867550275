import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { take, map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class UserGuard  {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.user$.pipe(
      take(1),
      map((u: User) => {
        if (
          u !== null &&
          (u.roles.includes('User') ||
            u.roles.includes('TrustedUser') ||
            u.roles.includes('Admin') ||
            u.roles.includes('SuperAdmin'))
        ) {
          // this.router.navigate(next.url);
          return true;
        }
        this.router.navigate(['tabs/home']);
        return false;
      })
    );
  }
}
