import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, RequestOptions } from '@angular/http';
import { Config } from '../common/config';
import { Observable } from 'rxjs';
import { Prediction } from '../models/models';

@Injectable()
export class DataService {
  constructor(private http: HttpClient, private config: Config) {}

  getTeams(league: number): Observable<any> {
    const url = this.config.serviceUrl + 'matches/apiteams/' + league;
    return this.http.get(url);
  }

  getNewsItems(league: number, numItems: number): Observable<any> {
    const url = `${this.config.serviceUrl}/news/getItems/${numItems}/${league}`;
    return this.http.get(url);
  }

  getResults(
    season: number,
    startWeek: number,
    endWeek: number,
    league: number
  ): Observable<any> {
    const url = `${this.config.serviceUrl}/results/getresults/${season}/${startWeek}/${endWeek}/${league}`;
    return this.http.get(url);
  }

  getPredictions(
    season: number,
    week: number,
    league: number
  ): Observable<any> {
    const url = `${this.config.serviceUrl}/predictions/getweekspredictions/${season}/${week}/${league}`;
    return this.http.get(url);
  }

  getNumBonus(season: number, week: number, player: number): Observable<any> {
    const url = `${this.config.serviceUrl}/predictions/getnumbonus/${season}/${week}/${player}`;
    return this.http.get(url);
  }

  getMatches(season: number, week: number): Observable<any> {
    const url = `${this.config.serviceUrl}/matches/withevents/${season}/${week}`;
    return this.http.get(url);
  }

  getCurrentWeek(season: number): Observable<any> {
    const url = `${this.config.serviceUrl}/matches/getWeekNo/${season}`;
    return this.http.get(url);
  }
  getNewWeek(season: number, week: number, direction: number): Observable<any> {
    const url = `${this.config.serviceUrl}/matches/nextWeek/${season}/${week}/${direction}`;
    return this.http.get(url);
  }
  updateProfile(data: any): Observable<any> {
    const url = this.config.serviceUrl + 'accounts/updateuser';
    return this.http.post(url, data);
  }
  updatePassword(data: any): Observable<any> {
    const url = this.config.serviceUrl + 'accounts/changepassword';
    return this.http.post(url, data);
  }
  savePredictions(preds: Array<Prediction>): Observable<any> {
    const url = `${this.config.serviceUrl}/predictions/put`;
    return this.http.post(url, JSON.stringify(preds));
  }

  savePrediction(pred: Prediction): Observable<any> {
    const arr: Array<Prediction> = new Array<Prediction>();
    arr.push(pred);
    return this.savePredictions(arr);
  }

  private addHeader(): HttpHeaders {
    const currentUser = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentUser && currentUser.access_token) {
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + currentUser.access_token,
      });

      return headers;
    }
  }

  private addHeaderJSON(): HttpHeaders {
    const currentUser = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentUser && currentUser.access_token) {
      const headers = new HttpHeaders();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return headers;
    }
  }
  private addHeaderJSON_1(): HttpHeaders {
    const currentUser = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentUser && currentUser.access_token) {
      const headers = new HttpHeaders();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      return headers;
    }
  }
}
