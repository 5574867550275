export class User {
  userName: string;
  isAuth: boolean;
  userRetrieved: boolean;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  roles: string[];
  claims: string[];
  player: number;
  league: number;
}
