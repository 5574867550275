import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpHeaders,
} from '@angular/common/http';
import { AuthenticationService } from '../services/services';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return next
      .handle(this.addTokenToRequest(request, this.authService.getAuthToken()))
      .pipe(
        catchError(err => {
          if (
            err instanceof HttpErrorResponse
            // && !request.url.includes('/oauth/token')
          ) {
            switch ((err as HttpErrorResponse).status) {
              case 401:
                return this.handle401Error(request, next);
              case 400:
                return this.authService.logout() as any;
            }
          } else {
            return throwError(err);
          }
        })
      );
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log('handle401: ' + this.isRefreshingToken);
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((user: any) => {
          if (user) {
            this.tokenSubject.next(user.access_token);
            return next.handle(
              this.addTokenToRequest(request, user.access_token)
            );
          }

          return this.authService.logout() as any;
        }),
        catchError(err => {
          return this.authService.logout() as any;
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }
  }
}
