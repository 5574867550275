import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import {
  DataService,
  AuthenticationService,
  TokenService,
  MyStorageService,
} from './services/services';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { Config } from './common/config';
import { UserService } from './services/user.service';
import { MatchComponent } from './components/match/match.component';
import { PredictionComponent } from './components/prediction/prediction.component';
import { MatchesPageModule } from './pages/matches/matches.module';
import { environment } from '../environments/environment';
import { UserGuard } from './guards/user.guard';
import { ChatService } from './services/chat.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
const config: SocketIoConfig = {
  url: environment.chatUrl,
  // socket.io options
  options: {
    reconnection: true,
    transports: ['websocket'],
  },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        SocketIoModule.forRoot(config),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        DataService,
        AuthenticationService,
        TokenService,
        MyStorageService,
        ChatService,
        Config,
        UserGuard,
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
