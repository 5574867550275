import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import {Socket } from 'ngx-socket-io';
import * as _ from 'lodash';

import { Chat } from '../models/chat';
import { Config } from '../common/config';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  messages: Chat[];
  messages$: BehaviorSubject<Chat[]>;
  message$: Subject<Chat>;
  private isStarted: boolean;
  constructor(
    private http: HttpClient,
    private config: Config,
    private socket: Socket
  ) {
    this.messages$ = new BehaviorSubject<Chat[]>([]);
    this.message$ = new Subject<Chat>();
    this.isStarted = false;
  }

  start(league: number) {
    console.log('start chat service ' + this.config.chatUrl);
    if (!this.isStarted) {
      console.log('Starting Chat service');
      this.http
        .get(this.config.chatUrl + '/api/chat/getLatest/100' + '/' + league)
        .subscribe(
          success => {
            this.isStarted = true;
            this.messages = [];
            console.log('Get success');
            const data = success;
            const arr = _.orderBy(data, ['id']);
            for (const mess of arr) {
              this.messages.push(mess);
            }
            this.messages$.next(this.messages);
          },
          error => {
            console.log('Get failed');
            console.log(JSON.stringify(error));
          }
        );
      this.socket.connect();
      this.socket.emit('join', { channel: 0 });
      this.socket.fromEvent<Chat>('new_entry').subscribe(mess => {
                console.log('New entry: ' + JSON.stringify(mess));
                this.messages.push(mess);
                this.messages$.next(this.messages);
                this.message$.next(mess);
              });
      this.socket.fromEvent<Chat>('upd_entry').subscribe(mess => {
                console.log('Update entry');
                this.message$.next(mess);
                this.update_message(mess);
              });
    }
  }

  update_message(mess: Chat) {
    const item = this.messages.find(x => x.eventId === mess.eventId);
    if (item) {
      item.icon = mess.icon;
      item.title = mess.title;
      item.description = mess.description;
    }
  }

  getText(mess: Chat) {
    console.log('getText : ' + mess.icon);
    let retVal =
      '<img style="width:8px;height:8px;padding-right:5px;" src="' +
      this.getImage(mess.icon) +
      '" />';
    retVal += ' ' + mess.title;
    return retVal;
  }
  getImage(type: string): string {
    console.log('getImage : ' + type);
    let retVal = 'assets/images/';
    switch (type) {
      case '1': {
        retVal += 'goal.png';
        break;
      }
      case '2': {
        retVal += 'yellow-card.png';
        break;
      }
      case '3': {
        retVal += 'red.png';
        break;
      }
      case '4': {
        retVal += 'double-yellow.png';
        break;
      }
      case '5': {
        retVal += 'penalty.png';
        break;
      }
      case '6': {
        retVal += 'ownGaol.png';
        break;
      }
      case '7': {
        retVal += 'missed-penalty.png';
        break;
      }
      default: {
        retVal += 'chat.png';
        break;
      }
    }
    console.log(retVal);
    return retVal;
  }
}
