import { Injectable } from '@angular/core';

@Injectable()
export class MyStorageService {
  constructor() {}

  get(key: string): any {
    const value = localStorage.getItem(key);
    return JSON.parse(value);
  }

  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }
}
