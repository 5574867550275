import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  {
    path: 'results/:weekNum',
    loadChildren: () => import('./pages/results/results.module').then(m => m.ResultsPageModule),
  },
  {
    path: 'matches/:weekNum',
    loadChildren: () => import('./pages/matches/matches.module').then(m => m.MatchesPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
  },
  { path: 'match-details', loadChildren: () => import('./pages/match-details/match-details.module').then(m => m.MatchDetailsPageModule) },
  { path: 'match-predictions', loadChildren: () => import('./pages/match-predictions/match-predictions.module').then(m => m.MatchPredictionsPageModule) },  { path: 'live-chat', loadChildren: () => import('./pages/live-chat/live-chat.module').then(m => m.LiveChatPageModule) },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
