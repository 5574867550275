import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class Config {
  baseUrl = environment.baseUrl;
  clientId = environment.clientId;
  chatUrl = environment.chatUrl;
  // baseUrl = 'http://plgapitest.lidnet.dk';

  public version = '1.7';
  public currentSeason = 13;
  serviceUrl: string = this.baseUrl + '/api/';
  authUrl: string = this.baseUrl + '/oauth/token';

  public currentWeek: number;
  public maxWeek: number;
  writeLog = true;
  constructor() {
    console.log('Config ' + this.version);
  }

  public setData(values: any) {
    this.currentSeason = values.thisSeason;
    this.currentWeek = values.thisWeek;
    this.maxWeek = values.maxWeek;
  }

  public getWeekNum(): number {
    return this.currentWeek;
  }
  public getSeasonNum(): number {
    return this.currentSeason;
  }
  public getMaxWeekNum(): number {
    return this.maxWeek;
  }
}
