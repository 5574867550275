import { Injectable } from '@angular/core';
import { MyStorageService } from './my-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TokenService {
  private dummyToken: any = {
    access_token: '',
    userName: 'Undefined',
    refresh_token: '',
  };
  private num = 0;
  private tempSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.num
  );
  public temp$: Observable<any> = this.tempSubject$.asObservable();
  private interval: any;

  private tokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.dummyToken
  );
  public token$: Observable<any>;
  constructor(private storage: MyStorageService) {
    this.token$ = this.tokenSubject$.asObservable();
    let data: any = this.storage.get('authorizationData');
    if (data) {
    } else {
      data = this.dummyToken;
    }
    this.tokenSubject$.next(data);
    // this.interval = setInterval(() => this.updateVal(), 5000);
  }

  setToken(token: any) {
    this.storage.set('authorizationData', token);
    // this.tempSubject$.next(this.num);
    this.num++;
    this.tokenSubject$.next(token);
  }

  getUser() {
    const tok = this.storage.get('authorizationData');
    if (tok !== null && tok.access_token !== '') {
      this.tokenSubject$.next(tok);
    }
  }
  updateVal() {
    this.num++;
    // console.log('UpdateVal: ' + this.num);
    this.tempSubject$.next(this.num);
  }
}
