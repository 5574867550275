import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Config } from '../common/config';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private dataService: DataService, private config: Config) {}

  getWeekNo() {
    console.log('getWeekNo');
    this.dataService.getCurrentWeek(-1).subscribe(
      res => {
        this.config.setData(res);
        this.config.currentWeek = res.thisWeek;
        this.config.maxWeek = res.maxWeek;
        this.config.currentSeason = res.thisSeason;
        console.log('gotWeekNo');
      },
      err => {
        console.log('Error');
        this.config.currentWeek = -2;
      }
    );
  }
  async getWeekNoAsync() {
    console.log('GetWeekNoAsync : ');
    const v = await this.dataService.getCurrentWeek(-1).toPromise();
    this.config.currentWeek = v.thisWeek;
    this.config.currentSeason = v.thisSeason;
    this.config.maxWeek = v.maxWeek;
    console.log('GetWeekNoAsync : done');
  }

}
