import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenService } from './token.service';
import { User } from '../models/user';
import { Config } from '../common/config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {
  private authData: User;
  private userSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  user$: Observable<User>;
  constructor(
    private http: HttpClient,
    private token: TokenService,
    private config: Config
  ) {
    this.authData = new User();
    this.user$ = this.userSubject$.asObservable();
    this.token.token$.subscribe(t => this.getUserInfo(t));
  }

  private getUserInfo(tok: any) {
    const url: string = this.config.serviceUrl + 'accounts/LocalUserInfo';
    if (tok !== null && tok.access_token !== '') {
      this.http.get(url).subscribe((response: User) => {
        this.authData.isAuth = true;
        this.authData.userRetrieved = true;
        this.authData.email = response.email;
        this.authData.userName = response.userName;
        this.authData.roles = response.roles;
        this.authData.claims = response.claims;
        this.authData.firstName = response.firstName;
        this.authData.lastName = response.lastName;
        this.authData.displayName = response.displayName;
        this.authData.player = response.player;
        this.authData.league = response.league;
        // this.setLoggedIn(true, 5);
        this.userSubject$.next(this.authData);
      });
    }
  }
}
